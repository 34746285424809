@tailwind base;
@tailwind components;
@tailwind utilities;

@import "highlight.js/styles/github-dark.css";

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@layer base {
  a {
    text-decoration: underline #b1acaa;
  }

  a:hover {
    text-decoration: underline #fff;
  }

  :root {
    --red-50: #ffffff;
    --red-500: #dd3636;
    --red-800: #571922;
    --red-900: #2a0e14;

    --grey-main: #c6c2bf;
    --grey-muted: #b1acaa;
    --grey-elevated: #dddad7;
    --grey-elevated-100: #e9e7e4;
    --grey-elevated-200: #f1eeeb;
    --grey-elevated-300: #f9f7f4;
    --grey-dark: #2b2826;
    --grey-dark-100: #22201e;
    --grey-dark-200: #110f0e;

    /* Assignment of colors */
    --background: var(--grey-main);
    --foreground: var(--grey-dark-200);

    --elevated: var(--grey-elevated);
    --elevated-100: var(--grey-elevated-100);
    --elevated-200: var(--grey-elevated-200);
    --elevated-300: var(--grey-elevated-300);

    --card: var(--grey-muted);
    --card-foreground: var(--grey-dark-200);

    --popover: var(--elevated);
    --popover-foreground: var(--grey-dark);

    --primary: var(--grey-dark-100);
    --primary-foreground: var(--grey-elevated-100);

    --secondary: var(--grey-dark-100);
    --secondary-foreground: var(--grey-elevated-200);

    --muted: var(--grey-muted);
    --muted-foreground: var(--grey-dark);

    --accent: var(--grey-dark);
    --accent-foreground: var(--grey-elevated);

    --destructive: var(--red-500);
    --destructive-foreground: var(--red-800);

    --border: var(--grey-main);
    --input: var(--grey-main);
    --ring: var(--grey-main);

    --radius: 0.5rem;
  }

  .dark {
    /* Assignment of colors */
    --background: var(--grey-dark-200);
    --foreground: var(--grey-main);

    --elevated: var(--grey-main-elevated);
    --elevated-100: var(--grey-main-elevated-100);
    --elevated-200: var(--grey-main-elevated-200);
    --elevated-300: var(--grey-main-elevated-300);

    --card: var(--grey-dark-100);
    --card-foreground: var(--grey-muted);

    --popover: var(--grey-dark-100);
    --popover-foreground: var(--elevated);

    --primary: var(--grey-elevated-300);
    --primary-foreground: var(--grey-dark-200);

    --secondary: var(--grey-elevated-200);
    --secondary-foreground: var(--grey-dark-100);

    --muted: var(--grey-dark);
    --muted-foreground: var(--grey-muted);

    --accent: var(--grey-elevated-200);
    --accent-foreground: var(--grey-dark-100);

    --destructive: var(--red-500);
    --destructive-foreground: var(--red-800);

    --border: var(--muted);
    --input: var(--muted);
    --ring: var(--muted);

    --radius: 0.5rem;
  }

  @layer base {
    * {
      @apply border-border;
    }
    body {
      @apply bg-background text-foreground;
    }
  }

  /* Custom MDX editor styles */
  .dark-editor {
    --accentBase: var(--tomato-1);
    --accentBgSubtle: var(--tomato-2);
    --accentBg: var(--tomato-3);
    --accentBgHover: var(--tomato-4);
    --accentBgActive: var(--tomato-5);
    --accentLine: var(--tomato-6);
    --accentBorder: var(--tomato-7);
    --accentBorderHover: var(--tomato-8);
    --accentSolid: var(--tomato-9);
    --accentSolidHover: var(--tomato-10);
    --accentText: var(--tomato-11);
    --accentTextContrast: var(--tomato-12);

    --baseBase: var(--mauve-1);
    --baseBgSubtle: var(--mauve-2);
    --baseBg: var(--mauve-3);
    --baseBgHover: var(--mauve-4);
    --baseBgActive: var(--mauve-5);
    --baseLine: var(--mauve-6);
    --baseBorder: var(--mauve-7);
    --baseBorderHover: var(--mauve-8);
    --baseSolid: var(--mauve-9);
    --baseSolidHover: var(--mauve-10);
    --baseText: var(--mauve-11);
    --baseTextContrast: var(--mauve-12);

    --admonitionTipBg: var(--green4);
    --admonitionTipBorder: var(--green8);

    --admonitionInfoBg: var(--grass4);
    --admonitionInfoBorder: var(--grass8);

    --admonitionCautionBg: var(--amber4);
    --admonitionCautionBorder: var(--amber8);

    --admonitionDangerBg: var(--red4);
    --admonitionDangerBorder: var(--red8);

    --admonitionNoteBg: var(--mauve-4);
    --admonitionNoteBorder: var(--mauve-8);

    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace;

    --basePageBg: black;
  }
}

.custom-code-editor-content {
  color: var(--foreground) !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  ul,
  ol,
  code,
  strong {
    color: var(--foreground) !important;
  }

  span[data-lexical-decorator="true"] {
    color: var(--foreground) !important;
  }
  div[data-lexical-decorator="true"] {
    background-color: var(--background);
    border-radius: 0.5rem;
  }

  li[role="checkbox"]::before,
  li[role="checkbox"]::after {
    margin-block: 0.25rem;
  }

  li[role="checkbox"]::before {
    border: 1px solid var(--foreground);
    border-radius: 0.25rem;
  }

  li[role="checkbox"][aria-checked="true"]::before {
    background-color: var(--popover);
  }

  li[role="checkbox"]:active::before,
  li[role="checkbox"]:focus::before {
    box-shadow: 0 0 0 2px var(--background);
  }

  code {
    span {
      background-color: var(--background);
    }
  }

  .cm-editor {
    background: transparent;

    .cm-activeLineGutter {
      background: var(--popover);
      color: var(--foreground);
    }
  }
}
